import React from 'react';
import { images } from '../constants';
import './howToUse.css';
import { t } from '../../utils/Language';
import { BUILD_LANGUAGE } from '../../config';

const HowToUse = () => {
  return (
    <div className='app_howtouse' id='how-to-use'>
      <div className='app_howtouse-overlay flex__center'>
        <img
          style={{ opacity: BUILD_LANGUAGE === 'tr' ? 0.4 : 0.9 }}
          src={images.medical_4}
          alt='G_overlay'
        />
      </div>

      <div className='app_howtouse-content'>
        <h1 className='app_howtouse_title'>{t('howToUse')}</h1>
        {BUILD_LANGUAGE === 'en' && (
          <div className='app_howtouse-content-container'>
            <div className='app_howtouse-content_section'>
              <p style={{ maxWidth: '60%' }} className='app_howtouse_text'>
                <b>1. </b> {t('htu_item_1')} <br /> <br />
                <b>2. </b> {t('htu_item_2')} <br /> <br />
                <b>3. </b> {t('htu_item_3')} <br /> <br />
                <b>4. </b> {t('htu_item_4')} <br /> <br />
                <b>5. </b> {t('htu_item_5')} <br /> <br />
                <b>6. </b> {t('htu_item_6')} <br /> <br />
                <b>7. </b> {t('htu_item_7')} <br /> <br />
                <b>8. </b> {t('htu_item_8')} <br /> <br />
              </p>
            </div>
          </div>
        )}

        {BUILD_LANGUAGE === 'tr' && (
          <div className='app_howtouse-content-container'>
            <div className='app_howtouse-content_section'>
              <p style={{ maxWidth: '90%' }} className='app_howtouse_text'>
                <b>1. </b> {t('htu_item_1')} <br /> <br />
                <b>2. </b> {t('htu_item_2')} <br /> <br />
                <b>3. </b> {t('htu_item_3')} <br /> <br />
                <b>4. </b> {t('htu_item_4')} <br /> <br />
              </p>
            </div>
            <div className='app_howtouse-content_section'>
              <p className='app_howtouse_text'>
                <b>5. </b> {t('htu_item_5')} <br /> <br />
                <b>6. </b> {t('htu_item_6')} <br /> <br />
                <b>7. </b> {t('htu_item_7')} <br /> <br />
                <b>8. </b> {t('htu_item_8')} <br /> <br />
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HowToUse;
