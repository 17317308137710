import { BUILD_LANGUAGE } from '../../config';
import dogalzeka from '../../assets/dogalzeka.png';
import profile from '../../assets/profile.jpg';
import medical_1 from '../../assets/medical-1.jpg';
import medical_2 from '../../assets/medical-2.jpeg';
import medical_3 from '../../assets/medical-3.png';
import prms from '../../assets/prms.png';
import whatsapp from '../../assets/WhatsApp.svg';
import instagram from '../../assets/instagram.svg';
const slider_1 = require(`../../assets/medical-slider-1-${BUILD_LANGUAGE}.jpg`);
const slider_2 = require(`../../assets/medical-slider-2-${BUILD_LANGUAGE}.jpg`);
const slider_3 = require(`../../assets/medical-slider-3-${BUILD_LANGUAGE}.jpg`);
const slider_4 = require(`../../assets/medical-slider-4-${BUILD_LANGUAGE}.jpg`);
const medical_5 = require(`../../assets/medical-5-${BUILD_LANGUAGE}.png`);
const medical_4 = require(`../../assets/medical-4-${BUILD_LANGUAGE}.jpg`);

export default {
  dogalzeka,
  profile,
  medical_1,
  medical_2,
  medical_3,
  medical_4,
  slider_1,
  slider_2,
  slider_3,
  slider_4,
  medical_5,
  prms,
  whatsapp,
  instagram,
};
