import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Typography, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { createUseStyles } from 'react-jss';

import {
  getRecordType,
  acceptedFileTypes,
  acceptedFileMimeTypes,
  RECORD_PATIENT_PICTURE,
  RECORD_PATIENT_VIDEO,
  RECORD_PATIENT_DOCUMENT,
} from '../../utils/utils';
import convert from 'heic-convert/browser';
import { generateVideoThumbnails as generateThumbnailForVideo } from '@rajesh896/video-thumbnails-generator';
import PdfThumbnailGenerator from './PDFThumbnailGenerator';
import Dropzone from 'react-dropzone';
import MediaCard from './MediaCard';
import MediaDetailModal from './MediaDetailModal';
import { t2 } from '../../utils/Language';

const MAX_FILE_COUNT = 50;

const useStyles = createUseStyles({
  mediaContainer: {
    height: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  dropzone: {
    height: '100%',
    backgroundColor: '#fafafa',
    color: '#9b9b9b',
    borderRadius: 6,
    cursor: 'pointer',
    border: '3px dashed #d9d9d9',
    transition: 'border-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      borderColor: 'cornflowerblue',
      color: 'cornflowerblue',
    },
  },
  dropzoneHighlight: {
    borderColor: 'cornflowerblue',
    color: 'cornflowerblue',
  },
  thumbnailContainer: {
    width: 170,
    height: 150,
    margin: 10,
  },
  disabledOverlay: {
    position: 'absolute',
    display: 'block',
    borderRadius: 6,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: 2,
  },
});

const MediaDropSelectPane = forwardRef(function (props, ref) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [candidates, setCandidates] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [mediaDetailModal, setMediaDetailModal] = useState(false);
  const clickedMediaIdx = useRef(null);

  useEffect(() => {
    if (props.disabled !== undefined) setDisabled(props.disabled);
  }, [props.disabled]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getFileList() {
          return candidates.filter((cand) => cand.selected);
        },
        clearUploadedFiles(files) {
          files.forEach((file) => {
            setCandidates((cands) => {
              const tempCandidates = [...cands];

              const idx = tempCandidates.findIndex(
                (cand) => file.path === cand.path,
              );
              tempCandidates.splice(idx, 1);

              return tempCandidates;
            });
          });
        },
        disable() {
          setDisabled(true);
        },
        enable() {
          setDisabled(false);
        },
      };
    },
    [candidates],
  );

  const handleFiles = (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > MAX_FILE_COUNT) {
      enqueueSnackbar(
        `Aynı anda ${MAX_FILE_COUNT} medyadan fazla yükleyemezsiniz!`,
        {
          variant: 'error',
        },
      );

      return false;
    }

    if (acceptedFiles.length) {
      props.onReady(false);

      setCandidates((cands) => {
        const newCands = [];

        acceptedFiles.forEach((af) => {
          if (af.size > 104857600) {
            enqueueSnackbar('İzin verilen maks. dosya boyutu 100 MB', {
              variant: 'warning',
            });
          } else {
            const f = cands.find(({ file: cf }) => cf.name === af.name);

            if (f) {
              enqueueSnackbar(`Şu medya zaten var: ${af.name}`, {
                variant: 'warning',
              });
            } else {
              newCands.push({
                file: af,
                thumbnail: null,
                selected: true,
                recordType: getRecordType(af),
              });
            }
          }
        });

        if (newCands.length === 0) {
          if (cands.length > 0) props.onReady(true);
          return cands;
        }

        const images = [],
          videos = [],
          documents = [];

        newCands.forEach(({ file, recordType }) => {
          switch (recordType) {
            case RECORD_PATIENT_PICTURE:
              images.push(file);
              break;
            case RECORD_PATIENT_VIDEO:
              videos.push(file);
              break;
            case RECORD_PATIENT_DOCUMENT:
              documents.push(file);
              break;
          }
        });

        if (images.length > 0) generateImageThumbnails(images);
        if (documents.length > 0) generateDocumentThumbnails(documents);
        if (videos.length > 0) generateVideoThumbnails(videos);

        return [...newCands, ...cands];
      });
    }

    if (fileRejections.length) {
      enqueueSnackbar(
        `Kabul edilen medya tipleri: ${acceptedFileTypes.join(', ')}`,
        { variant: 'error' },
      );
    }
  };

  ////// thumbnail generation utils //////
  const waitingImageThumbnails = useRef(false);
  const waitingVideoThumbnails = useRef(false);
  const waitingDocumentThumbnails = useRef(false);
  const [pdfFiles, setPdfFiles] = useState([]);
  const videoFileCount = useRef([]);
  const videoThumbnails = useRef([]);

  const generateImageThumbnails = (images) => {
    waitingImageThumbnails.current = true;
    const thumbs = [];

    function pushImageThumbnail(data, fileName, error = false) {
      thumbs.push({
        data,
        fileName,
        error,
      });

      if (images.length === thumbs.length) {
        // all image thumbnails done
        handleImageThumbnailsComplete(thumbs);
      }
    }

    images.forEach((img) => {
      const reader = new FileReader();

      reader.addEventListener('load', function (event) {
        if (img.type === 'image/heic' || img.type === 'image/heif') {
          convert({
            buffer: new Uint8Array(event.target.result), // the HEIC file buffer
            format: 'JPEG', // output format
            quality: 1, // the jpeg compression quality, between 0 and 1
          }).then(
            (outputBuffer) => {
              function bufferToBase64(buffer) {
                let binary = '';
                const len = buffer.byteLength;
                for (var i = 0; i < len; i++) {
                  binary += String.fromCharCode(buffer[i]);
                }
                return 'data:image/jpeg;base64,' + window.btoa(binary);
              }

              const base64String = bufferToBase64(outputBuffer);

              pushImageThumbnail(base64String, img.name);
            },
            (err) => {
              console.error('err:', err);

              pushImageThumbnail(null, img.name, true);
            },
          );
        } else {
          pushImageThumbnail(event.target.result, img.name);
        }
      });

      if (img.type === 'image/heic' || img.type === 'image/heif') {
        reader.readAsArrayBuffer(img);
      } else {
        reader.readAsDataURL(img); // convert to base64 string
      }
    });
  };

  const handleImageThumbnailsComplete = (thumbnails) => {
    waitingImageThumbnails.current = false;
    handleThumbnails(thumbnails);
  };

  const generateDocumentThumbnails = (documents) => {
    waitingDocumentThumbnails.current = true;
    setPdfFiles(documents);
  };

  const handleDocumentThumbnailsComplete = (thumbnails) => {
    const thumbs = [];

    thumbnails.forEach((t) => {
      const reader = new FileReader();

      reader.addEventListener('load', function (event) {
        thumbs.push({
          data: event.target.result,
          fileName: t.fileName,
          file: t.thumbnail,
        });

        if (thumbnails.length === thumbs.length) {
          // all document thumbnails done
          waitingDocumentThumbnails.current = false;
          handleThumbnails(thumbs);
        }
      });

      reader.readAsDataURL(t.thumbnail); // convert to base64 string
    });

    setPdfFiles([]);
  };

  const generateVideoThumbnails = (videos) => {
    waitingVideoThumbnails.current = true;
    videoFileCount.current = videos.length;

    videos.forEach((video, idx) => {
      generateThumbnailForVideo(video, 0, 'file').then((res) =>
        handleVideoThumbnail(res, idx, video.name),
      );
    });
  };

  const handleVideoThumbnail = (thumbnail, idx, fname) => {
    videoThumbnails.current[idx] = { data: thumbnail, fileName: fname };

    const len = videoThumbnails.current.reduce((sum, el) => {
      if (el) return sum + 1;
      return sum;
    }, 0);

    if (len === videoFileCount.current) {
      waitingVideoThumbnails.current = false;
      handleThumbnails(videoThumbnails.current);
    }
  };

  const handleThumbnails = (thumbs) => {
    setCandidates((cands) => {
      const tempCandidates = [...cands];

      thumbs.forEach((thumb) => {
        const idx = tempCandidates.findIndex(
          (cand) => cand.file.name === thumb.fileName,
        );

        if (idx !== -1) {
          tempCandidates[idx].thumbnail = thumb;
        } else {
          console.error('Could not find file', thumb.fileName);
        }
      });

      return tempCandidates;
    });

    if (
      !waitingImageThumbnails.current &&
      !waitingDocumentThumbnails.current &&
      !waitingVideoThumbnails.current
    ) {
      props.onReady(true);
    }
  };
  ////// thumbnail generation utils end //////

  const handleClickMedia = (idx) => {
    clickedMediaIdx.current = idx;

    setMediaDetailModal(true);
  };

  const handleDeleteMedia = (idx) => {
    setCandidates((cands) => {
      const temp = [...cands];
      temp.splice(idx, 1);

      if (temp.length === 0) props.onReady(true);
      return temp;
    });
  };

  return (
    <div className={classes.mediaContainer} style={props.style}>
      <Dropzone
        onDrop={handleFiles}
        accept={acceptedFileMimeTypes}
        maxFiles={MAX_FILE_COUNT}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            style={{
              width: '100%',
              position: 'relative',
            }}>
            <div
              {...getRootProps({
                className: `${classes.dropzone} ${
                  isDragActive ? classes.dropzoneHighlight : ''
                }`,
              })}>
              <input {...getInputProps()} />
              {candidates.length === 0 && (
                <Stack
                  justifyContent='center'
                  alignItems='center'
                  style={{
                    width: '100%',
                    height: 190,
                  }}>
                  <Typography align='center'>{t2.dropHere}</Typography>
                </Stack>
              )}
              {candidates.length > 0 && (
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='flex-start'
                  flexWrap='wrap'
                  style={{
                    width: '100%',
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}>
                  {candidates.map((cand, idx) => (
                    <MediaCard
                      key={cand.file.path}
                      type={RECORD_PATIENT_PICTURE} // since the generated thumbnails are images
                      recordIconType={cand.recordType}
                      src={cand.thumbnail?.data ?? null}
                      error={cand.thumbnail?.error}
                      title={cand.file.name}
                      containerClass={classes.thumbnailContainer}
                      onClick={() => handleClickMedia(idx)}
                      onDelete={() => handleDeleteMedia(idx)}
                    />
                  ))}
                </Stack>
              )}
            </div>
            <div
              className={classes.disabledOverlay}
              style={{ display: disabled ? 'block' : 'none' }}></div>
          </div>
        )}
      </Dropzone>

      {pdfFiles.length > 0 && (
        <PdfThumbnailGenerator
          files={pdfFiles}
          onThumbnailsGenerated={handleDocumentThumbnailsComplete}
        />
      )}

      {mediaDetailModal && (
        <MediaDetailModal
          open={mediaDetailModal}
          handleClose={() => {
            setMediaDetailModal(false);
          }}
          url={URL.createObjectURL(candidates[clickedMediaIdx.current].file)}
          type={candidates[clickedMediaIdx.current].recordType}
        />
      )}
    </div>
  );
});

export default MediaDropSelectPane;
