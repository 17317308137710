import React, { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import tr from 'react-phone-input-2/lang/tr.json';
import 'react-phone-input-2/lib/material.css';
import { BUILD_LANGUAGE } from '../config';
import { t2 } from '../utils/Language';

/**
 * Label position and styling fixed PhoneInput
 */
function _PhoneInput({ value, inputClass, disabled }) {
  useEffect(() => {
    // Phone input label styling fix
    const phoneLabel = document
      .getElementById('phone-input')
      .getElementsByClassName('special-label')[0];
    phoneLabel.style.left = 0;
    phoneLabel.style.padding = 0;
    phoneLabel.style.color = 'rgba(0, 0, 0, 0.6)';
    phoneLabel.style.fontSize = '1rem';
    phoneLabel.style.transformOrigin = 'top left';
    phoneLabel.style.transform = 'scale(0.75)';
  }, []);

  return (
    <div id='phone-input'>
      <PhoneInput
        country={BUILD_LANGUAGE === 'tr' ? 'tr' : 'gb'}
        localization={BUILD_LANGUAGE === 'tr' ? tr : undefined}
        specialLabel={t2.phone}
        disabled={disabled}
        value={value}
        inputClass={inputClass}
      />
    </div>
  );
}

export default _PhoneInput;
