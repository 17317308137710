import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import ProfileImage from '../assets/profile.jpg';

const useStyles = createUseStyles({
  card: {
    float: 'left',
    width: 255,
    height: 285,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 20,
    cursor: 'pointer',
  },
  singleLineNoWrapEllipsis: {
    fontSize: '1.1rem',
    lineHeight: '1.2rem',
    maxHeight: '1.2rem',
    width: '220px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    float: 'left',
  },
  header: {},
  subheader: {
    fontSize: 13,
    lineHeight: '0.875rem',
    marginTop: 5,
    maxHeight: '1rem',
  },
  footerContainer: {
    width: '100%',
    height: 60,
    textAlign: 'left',
    padding: 0,
    paddingLeft: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  institution: {
    fontSize: '1rem',
  },
  address: {
    marginTop: 2,
    fontSize: '0.9rem',
    lineHeight: '0.875rem',
  },
  img: {
    width: 152,
  },
  '@media screen and (max-width: 768px)': {
    card: { margin: 5 },
    img: {
      width: 100,
    },
  },
});

function DoctorCard({
  id,
  title,
  proficiency,
  address,
  institution,
  photoUrl,
  onSelect,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    onSelect();
    navigate(`/doctor/${id}`);
  };

  return (
    <Card className={classes.card} onClick={handleClick} elevation={3}>
      <CardHeader
        titleTypographyProps={{
          className: `${classes.singleLineNoWrapEllipsis} ${classes.header}`,
        }}
        subheaderTypographyProps={{
          className: `${classes.singleLineNoWrapEllipsis} ${classes.subheader}`,
        }}
        title={title}
        subheader={proficiency}
      />
      <CardMedia
        component='img'
        alt='İmaj yüklenemedi'
        height={152}
        image={photoUrl ?? ProfileImage}
      />
      <div className={classes.footerContainer}>
        <Typography
          className={`${classes.singleLineNoWrapEllipsis} ${classes.institution}`}>
          {institution}
        </Typography>
        <Typography
          className={`${classes.singleLineNoWrapEllipsis} ${classes.address}`}>
          {address}
        </Typography>
      </div>
    </Card>
  );
}

export default DoctorCard;
