import InquiryListTable from '../components/Inquiry/InquiryListTable';
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { axiosGet, axiosDelete, errorHandler } from '../utils/utils';
import { publicPatientURL } from '../config';
import { CircularProgress, Paper, Stack } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { t2 } from '../utils/Language';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
});

const InquiryList = () => {
  const [loading, setLoading] = useState(true);
  const [inquiryList, setInquiryList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();

  const getData = async () => {
    setLoading(true);

    await axiosGet(`${publicPatientURL}/inquiries`, true)
      .then(({ data }) => {
        setInquiryList(data.inquiryList);
      })
      .catch((err) =>
        enqueueSnackbar(errorHandler(err, null), { variant: 'error' }),
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async (idx) => {
    setLoading(true);

    await axiosDelete(
      `${publicPatientURL}/inquiries/${inquiryList[idx]._id}`,
      true,
    )
      .then(async ({ data }) => {
        enqueueSnackbar(data.message, { variant: 'success' });
        await getData();
      })
      .catch((err) =>
        enqueueSnackbar(errorHandler(err, null), { variant: 'error' }),
      )
      .finally(() => setLoading(false));
  };

  const handleClick = (inquiryId, doctorId) => {
    navigate(`/inquiry/detail`, { state: { inquiryId, doctorId } });
  };

  return (
    <div className={classes.container}>
      {loading && (
        <Stack
          justifyContent='center'
          alignItems='center'
          style={{
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.1)',
            zIndex: 999,
          }}>
          <CircularProgress />
        </Stack>
      )}

      <Paper
        style={{
          justifyContent: 'flex-start',
          margin: 20,
          paddingTop: 20,
          paddingBottom: 20,
          width: '90vw',
        }}>
        {inquiryList && inquiryList.length > 0 && (
          <InquiryListTable
            inquiryData={inquiryList}
            handleClick={handleClick}
            handleDelete={handleDelete}
          />
        )}

        {!loading && (!inquiryList || inquiryList.length === 0) && (
          <div
            style={{
              textAlign: 'center',
              fontSize: 20,
              padding: 20,
            }}>
            {t2.noInquiryYet}
          </div>
        )}
      </Paper>
    </div>
  );
};

export default InquiryList;
