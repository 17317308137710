import { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { Modal, Fade, TextField, Button } from '@mui/material';
import { FaSave } from 'react-icons/fa';

const useStyles = createUseStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: 10,
    padding: 20,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
});

export default function EditMessageModal({
  open,
  messageText,
  onClose,
  onSave,
}) {
  const classes = useStyles();
  const inputRef = useRef();

  const handleSave = () => {
    onSave(inputRef.current.value);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition={true}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}>
      <Fade in={open}>
        <div className={classes.paper}>
          <TextField
            inputRef={inputRef}
            defaultValue={messageText}
            variant='outlined'
            label='Mesajınızı yazınız...'
            multiline
            minRows={4}
            maxRows={8}
            style={{ width: 500 }}
            autoFocus
            onFocus={
              (e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length,
                ) // move cursor to end. why this is needed?
            }
          />
          <div className={classes.buttonContainer}>
            <Button
              variant='contained'
              color='primary'
              size='medium'
              startIcon={<FaSave />}
              onClick={handleSave}>
              Kaydet
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
