import React from 'react';

import { images } from '../constants';
import './aboutUs.css';
import { t } from '../../utils/Language';
import { BUILD_LANGUAGE } from '../../config';

const AboutUs = () => {
  return (
    <div className='app_aboutus' id='aboutUs'>
      <div className='app_aboutus-img'>
        <img
          style={{ width: BUILD_LANGUAGE === 'tr' ? '60%' : '40%' }}
          src={images.medical_5}
          alt='G_overlay'
        />
      </div>
      <div className='app_aboutus-content'>
        <h1 className='app_aboutus-title'>{t('aboutUs')}</h1>
        <p style={{ color: 'black' }} className='app_aboutus-text'>
          {t('aboutUsDescription')}
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
