import React, { useState, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { Modal, Fade } from '@mui/material';
import ReactPlayer from 'react-player';

import {
  RECORD_PATIENT_DOCUMENT,
  RECORD_PATIENT_PICTURE,
  RECORD_PATIENT_VIDEO,
} from '../../utils/utils';

import ZoomPanImage from './ZoomPanImage';
import ImageModal from './ImageModal';
import PdfViewer from './PdfViewer';

const useStyles = createUseStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: 10,
    padding: 20,
    width: '60%',
    height: '50%',
    minHeight: 600,
    minWidth: 1260,
    outline: 'none',
    overflow: 'auto',
  },
  previewContainer: {
    background: 'black',
    height: '100%',
    flex: 1,
    marginLeft: 'auto',
    borderRadius: 10,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
});

export default function MediaDetailModal({ open, url, type, handleClose }) {
  const classes = useStyles();
  const [imageModal, setImageModal] = useState(false);
  const [isPlaying, setPlaying] = useState(true);
  const videoPlayerRef = useRef();

  return (
    <>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition={true}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <div
              style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
              <div className={classes.previewContainer}>
                {type === RECORD_PATIENT_PICTURE && (
                  <ZoomPanImage
                    src={url}
                    onFullscreen={() => setImageModal(true)}
                  />
                )}
                {type === RECORD_PATIENT_VIDEO && (
                  <>
                    <ReactPlayer
                      ref={videoPlayerRef}
                      url={url}
                      playing={isPlaying}
                      onPlay={() => setPlaying(true)}
                      onPause={() => setPlaying(false)}
                      onEnded={() => videoPlayerRef.current.seekTo(0)}
                      controls
                      width='100%'
                      height='100%'
                    />
                  </>
                )}
                {type === RECORD_PATIENT_DOCUMENT && <PdfViewer source={url} />}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      {imageModal && (
        <ImageModal
          open={imageModal}
          handleClose={() => setImageModal(false)}
          src={url}
        />
      )}
    </>
  );
}
