import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import Page from './routes/Page';
import Home from './routes/Home';
import Search from './routes/Search';
import Doctor from './routes/Doctor';
import { urlBaseName } from './config';
import { useEffect } from 'react';
import { DoctorListProvider } from './store/DoctorListContext';
import InquiryPageBase from './routes/InquiryPageBase';
import Login from './routes/Login/Login';
import InquiryList from './routes/InquiryList';
import NewInquiry from './routes/NewInquiry/NewInquiry';
import Inquiry from './routes/Inquiry/Inquiry';
import { t } from './utils/Language';
import { isAccessTokenValid } from './utils/utils';

function RequireAuth({ children }) {
  const location = useLocation();

  if (!isAccessTokenValid()) {
    localStorage.removeItem('accessToken');
    const message = t('loginToInquire');

    // Redirect them to the login page, but save the current location and state they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate to='/inquiry' state={{ from: location, message }} replace />
    );
  }

  return children;
}

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Page />,
      children: [
        {
          path: '/',
          element: <Home />,
        },

        {
          path: '/search',
          element: <Search />,
        },
        {
          path: '/doctor/:doctorId',
          element: <Doctor />,
        },
      ],
    },
    {
      path: '/inquiry',
      element: <InquiryPageBase />,
      children: [
        {
          path: '',
          element: <Login />,
        },
        {
          path: 'list',
          element: (
            <RequireAuth>
              <InquiryList />
            </RequireAuth>
          ),
        },
        {
          path: 'new',
          element: (
            <RequireAuth>
              <NewInquiry />
            </RequireAuth>
          ),
        },
        {
          path: 'detail',
          element: (
            <RequireAuth>
              <Inquiry />
            </RequireAuth>
          ),
        },
      ],
    },
  ],
  { basename: urlBaseName },
);

function Router() {
  return <RouterProvider router={router} />;
}

function App() {
  useEffect(() => {
    return () => {
      localStorage.clear();
    };
  }, []);

  return (
    <SnackbarProvider maxSnack={3}>
      <CssBaseline />
      <DoctorListProvider>
        {/* <AuthProvider> */}
        <Router />
        {/* </AuthProvider> */}
      </DoctorListProvider>
    </SnackbarProvider>
  );
}

export default App;
