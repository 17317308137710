import {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import {
  Card,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Checkbox,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import {
  axiosDelete,
  axiosPatch,
  errorHandler,
  getLocalizedDateString,
  getLocalizedDateTimeString,
} from '../../utils/utils';
import { publicPatientURL } from '../../config';
import MediaCard from './MediaCard';
import MediaDetailModal from './MediaDetailModal';
import { t2 } from '../../utils/Language';
import promiseLimit from 'promise-limit';
import { useSnackbar } from 'notistack';
import MessageViewToolbar from './MessageViewToolbar';
import EditMessageModal from './EditMessageModal';

const useStyles = createUseStyles({
  container: {
    marginTop: 24,
    backgroundColor: 'AliceBlue',
    borderRadius: 10,
    padding: 24,
    width: '100%',
    //border: '1px solid lightblue',
  },
  ownerMessageLine: {
    width: '100%',
    textAlign: 'right',
  },
  ownerMessageCard: {
    padding: 20,
    paddingBottom: 10,
    margin: 2,
    marginLeft: 'auto',
    maxWidth: '70%',
    display: 'inline-block',
    backgroundColor: 'lightblue',
  },
  otherMessageLine: { width: '100%' },
  otherMessageCard: {
    padding: 20,
    paddingBottom: 10,
    margin: 2,
    maxWidth: '70%',
    display: 'inline-block',
    backgroundColor: 'white',
  },
  messageText: {
    whiteSpace: 'pre-line',
    textAlign: 'left',
    marginBottom: 4,
  },
  mediaContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  thumbnailContainer: {
    width: 190,
    height: 150,
    margin: 10,
  },
  deleteButtonContainer: {
    display: 'inline',
    marginRight: 8,
  },
  deleteIconInactive: {
    color: 'darkgrey',
  },
  deleteIconActive: {
    color: '#cc1b1b',
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  infoLineContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    marginRight: 20,
    borderRadius: 25,
    background: 'rgba(255, 255, 255, 0.75)',
  },

  '@media screen and (max-width: 768px)': {
    container: {
      padding: 0,
    },
    ownerMessageCard: {
      padding: 10,
      paddingBottom: 5,
      maxWidth: '85%',
    },
    otherMessageCard: {
      padding: 10,
      paddingBottom: 5,
      maxWidth: '85%',
    },
  },
});

const InquiryMessage = forwardRef(function ({ message, onSelect }, ref) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        clearSelection() {
          if (checked) setChecked(false);
        },
      };
    },
    [],
  );

  const handleSelect = (e) => {
    setChecked(e.target.checked);
    onSelect(message.id, e.target.checked);
  };

  return (
    <Card
      className={
        message.isOwner ? classes.ownerMessageCard : classes.otherMessageCard
      }>
      <Typography
        className={classes.messageText}
        style={{
          textAlign: message.isOwner ? 'right' : 'left',
        }}>
        {message.text}
      </Typography>

      <div className={classes.infoLineContainer}>
        {message.isOwner ? (
          <div className={classes.checkboxContainer}>
            <Tooltip title='Seç'>
              <Checkbox
                checked={checked}
                color='primary'
                size='small'
                onClick={(e) => e.stopPropagation()}
                onChange={handleSelect}
              />
            </Tooltip>
          </div>
        ) : undefined}
        <Typography variant='caption'>
          {getLocalizedDateTimeString(message.timestamp)}
        </Typography>
      </div>
    </Card>
  );
});

const InquiryMedia = forwardRef(function ({ media, onSelect }, ref) {
  const classes = useStyles();
  const [mediaDetailModal, setMediaDetailModal] = useState(false);
  const [checked, setChecked] = useState(
    new Array(media.mediaList.length).fill(false),
  );
  const selectedMediaIdx = useRef();

  useImperativeHandle(
    ref,
    () => {
      return {
        clearSelection() {
          setChecked(new Array(media.mediaList.length).fill(false));
        },
      };
    },
    [],
  );

  useEffect(() => {
    setChecked(new Array(media.mediaList.length).fill(false));
  }, [media.mediaList.length]);

  const handleSelect = (id, idx, isSelected) => {
    setChecked((checked) => {
      const temp = [...checked];
      temp[idx] = isSelected;
      return temp;
    });
    onSelect(id, isSelected);
  };

  return (
    <>
      <Card
        className={
          media.isOwner ? classes.ownerMessageCard : classes.otherMessageCard
        }>
        <div className={classes.mediaContainer}>
          {media.mediaList?.map((m, idx) => (
            <MediaCard
              key={m.id}
              type={m.recordType}
              src={m.thumbnailUrl}
              title={getLocalizedDateString(m.timestamp)}
              containerClass={classes.thumbnailContainer}
              selected={checked[idx]}
              onSelect={
                m.isOwner ? handleSelect.bind(this, m.id, idx) : undefined
              }
              onClick={() => {
                selectedMediaIdx.current = idx;
                setMediaDetailModal(true);
              }}
            />
          ))}
        </div>
        <Typography variant='caption'>
          {getLocalizedDateTimeString(media.timestamp)}
        </Typography>
      </Card>

      {mediaDetailModal && (
        <MediaDetailModal
          open={mediaDetailModal}
          handleClose={() => {
            setMediaDetailModal(false);
          }}
          url={media.mediaList[selectedMediaIdx.current].url}
          type={media.mediaList[selectedMediaIdx.current].recordType}
        />
      )}
    </>
  );
});

function InquiryMessageView({
  inquiryId,
  messageList,
  mediaList,
  onUpdateRequired,
  onLoading,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMediaIds, setSelectedMediaIds] = useState([]);
  const [selectedMessageIds, setSelectedMessageIds] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [combinedList, setCombinedList] = useState([]);
  const [editMessageModal, setEditMessageModal] = useState(false);
  const itemEls = useRef([]);

  useEffect(() => {
    const sortedList = [...messageList, ...mediaList].sort((a, b) => {
      if (a.timestamp < b.timestamp) return -1;
      if (a.timestamp > b.timestamp) return 1;
      return 0;
    });

    const combinedList = [];
    let groupOfMedia = null;
    // {
    //   isOwner: false,
    //   id: null,
    //   timestamp: null,
    //   mediaList: [],
    // };

    sortedList.forEach((item, idx) => {
      if (item.text) {
        // message
        if (groupOfMedia) {
          combinedList.push(groupOfMedia);
          groupOfMedia = null;
        }

        combinedList.push(item);
      } else {
        // media
        if (!groupOfMedia) {
          groupOfMedia = {
            isOwner: item.isOwner,
            id: `group_${item.id}`,
            timestamp: item.timestamp,
            mediaList: [item],
          };
        } else {
          if (groupOfMedia.isOwner === item.isOwner) {
            groupOfMedia.mediaList.push(item);
          } else {
            combinedList.push(groupOfMedia);

            groupOfMedia = {
              isOwner: item.isOwner,
              id: `group_${item.id}`,
              timestamp: item.timestamp,
              mediaList: [item],
            };
          }
        }
      }

      if (idx === sortedList.length - 1 && groupOfMedia) {
        combinedList.push(groupOfMedia);
        groupOfMedia = null;
      }
    });

    setCombinedList(combinedList);
  }, [messageList, mediaList]);

  const handleMessageSelected = (id, isSelected) => {
    if (isSelected) {
      setSelectedMessageIds((ids) => {
        return [...ids, id];
      });
    } else {
      setSelectedMessageIds((ids) => {
        return ids.filter((_id) => _id !== id);
      });
    }
  };

  const handleMediaSelected = (id, isSelected) => {
    if (isSelected) {
      setSelectedMediaIds((ids) => {
        return [...ids, id];
      });
    } else {
      setSelectedMediaIds((ids) => {
        return ids.filter((_id) => _id !== id);
      });
    }
  };

  const handleDelete = () => {
    let deletedItemCount = 0;
    const totalItemCount = selectedMediaIds.length + selectedMessageIds.length;
    const limit = promiseLimit(5);

    const deleteMedia = async (id) => {
      await axiosDelete(
        `${publicPatientURL}/inquiries/${inquiryId}/media/${id}`,
        true,
      )
        .then(() => deletedItemCount++)
        .catch((err) =>
          enqueueSnackbar(errorHandler(err, null), { variant: 'error' }),
        );
    };

    const deleteMessage = async (id) => {
      await axiosDelete(
        `${publicPatientURL}/inquiries/${inquiryId}/messages/${id}`,
        true,
      )
        .then(() => deletedItemCount++)
        .catch((err) =>
          enqueueSnackbar(errorHandler(err, null), { variant: 'error' }),
        );
    };

    const promiseArray = [];
    if (selectedMediaIds.length) {
      selectedMediaIds.forEach((id) =>
        promiseArray.push(limit(() => deleteMedia(id))),
      );
    }
    if (selectedMessageIds.length) {
      selectedMessageIds.forEach((id) =>
        promiseArray.push(limit(() => deleteMessage(id))),
      );
    }
    if (promiseArray.length) {
      onLoading(true);
      Promise.all(promiseArray).then(() => {
        setSelectedMediaIds([]);
        setSelectedMessageIds([]);
        if (deletedItemCount > 0) {
          enqueueSnackbar(`${deletedItemCount} medya/mesaj silindi`, {
            variant: 'success',
          });
          if (deletedItemCount !== totalItemCount) {
            enqueueSnackbar(
              `${totalItemCount - deletedItemCount} medya/mesaj silinemedi.`,
              {
                variant: 'error',
              },
            );
          }
        } else {
          enqueueSnackbar('Medya ve mesjalar silinemedi.', {
            variant: 'error',
          });
        }
        onLoading(false);
        onUpdateRequired();
      });
    }
  };

  const handleClearSelection = () => {
    itemEls.current.forEach((elem) => {
      if (elem) elem.clearSelection();
    });

    setSelectedMediaIds([]);
    setSelectedMessageIds([]);
  };

  const handleEditMessage = () => {
    setEditMessageModal(true);
  };

  const handleEditMessageComplete = (messageText) => {
    onLoading(true);
    setEditMessageModal(false);
    axiosPatch(
      `${publicPatientURL}/inquiries/${inquiryId}/messages/${selectedMessageIds[0]}`,
      { message: messageText },
      true,
    )
      .then(() =>
        enqueueSnackbar('Mesajınız kaydedildi', { variant: 'success' }),
      )
      .catch((err) =>
        enqueueSnackbar(errorHandler(err, null), { variant: 'error' }),
      )
      .finally(() => {
        onLoading(false);
        onUpdateRequired();
        handleClearSelection([]);
      });
  };

  const editMessageEnabled =
    selectedMessageIds.length === 1 && selectedMediaIds.length === 0;

  return (
    <>
      <div className={classes.container}>
        <MessageViewToolbar
          show={selectedMediaIds.length > 0 || selectedMessageIds.length > 0}
          onClear={handleClearSelection}
          onEditMessage={editMessageEnabled ? handleEditMessage : undefined}
          onDelete={() => setDeleteDialog(true)}
        />

        {combinedList.map((item, idx) => {
          return (
            <div
              key={item.id}
              className={
                item.isOwner
                  ? classes.ownerMessageLine
                  : classes.otherMessageLine
              }>
              {item.text && (
                <InquiryMessage
                  ref={(element) => (itemEls.current[idx] = element)}
                  message={item}
                  onSelect={handleMessageSelected}
                />
              )}

              {item.mediaList && (
                <InquiryMedia
                  ref={(element) => (itemEls.current[idx] = element)}
                  media={item}
                  onSelect={handleMediaSelected}
                />
              )}
            </div>
          );
        })}
      </div>

      {editMessageModal && (
        <EditMessageModal
          open={editMessageModal}
          messageText={
            messageList.find((m) => m.id === selectedMessageIds[0]).text
          }
          onClose={() => setEditMessageModal(false)}
          onSave={handleEditMessageComplete}
        />
      )}

      <Dialog
        open={deleteDialog}
        onClose={() => {
          setDeleteDialog(false);
        }}>
        <DialogTitle>{t2.areYouSure}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t2.approvalToDeleteMedia}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)} color='primary'>
            {t2.cancel}
          </Button>
          <Button
            onClick={() => {
              setDeleteDialog(false);
              handleDelete();
            }}
            color='error'>
            {t2.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InquiryMessageView;
