import { Card, Tooltip, Slide } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { BsTrash } from 'react-icons/bs';
import { FiX } from 'react-icons/fi';
import { MdOutlineEditNote } from 'react-icons/md';

const useStyles = createUseStyles({
  toolbarContainer: {
    width: '100%',
    height: 0,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 999,
  },
  toolbarCard: {
    padding: 10,
    height: 44,
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'PaleGoldenrod',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteIcon: {
    color: '#cc1b1b',
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  cancelIcon: {
    color: 'black',
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  editIcon: {
    color: 'DarkCyan',
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  editDisabled: {
    color: 'grey',
    cursor: 'not-allowed',
  },
});

function MessageViewToolbar({ show, onClear, onEditMessage, onDelete }) {
  const classes = useStyles();

  return (
    <Slide
      style={{
        position: 'sticky',
        top: 110,
      }}
      direction='down'
      in={show}
      mountOnEnter
      unmountOnExit>
      <div className={classes.toolbarContainer}>
        <Card className={classes.toolbarCard}>
          <Tooltip title='Seçimi iptal et'>
            <div
              className={classes.buttonContainer}
              onClick={onClear}
              style={{ marginRight: 6 }}>
              <FiX className={classes.cancelIcon} size={24} />
            </div>
          </Tooltip>

          <Tooltip title='Mesajı düzenle'>
            <div
              className={classes.buttonContainer}
              onClick={onEditMessage}
              style={{ marginRight: 6 }}>
              <MdOutlineEditNote
                className={
                  onEditMessage ? classes.editIcon : classes.editDisabled
                }
                size={28}
              />
            </div>
          </Tooltip>

          <Tooltip title='Seçili ögeleri sil'>
            <div className={classes.buttonContainer} onClick={onDelete}>
              <BsTrash className={classes.deleteIcon} size={20} />
            </div>
          </Tooltip>
        </Card>
      </div>
    </Slide>
  );
}

export default MessageViewToolbar;
