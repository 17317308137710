import { useEffect, useState, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Paper,
  Avatar,
  TextField,
  Autocomplete,
  Button,
  CircularProgress,
  useMediaQuery,
  Stack,
} from '@mui/material';
import { MdSearch } from 'react-icons/md';
import { FaNotesMedical } from 'react-icons/fa';
import { axiosGet, cities, isAccessTokenValid } from '../utils/utils';
import { authURL, DR_HOST_ADDRESS, INQUIRY_HOST_ADDRESS } from '../config';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { DoctorListContext } from '../store/DoctorListContext';
import { t } from '../utils/Language';

const useStyles = createUseStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    width: '25vw',
    minWidth: 280,
    maxWidth: 400,
    maxHeight: '75vh',
    minHeight: 300,
  },
  paperHorizontal: {
    minHeight: '20vh',
    width: '60vw',
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    zIndex: 11,
  },
  avatar: {
    margin: 4,
    backgroundColor: 'rgb(227, 51, 113)',
    width: '10vh',
    height: '10vh',
    maxWidth: 120,
    maxHeight: 120,
    minWidth: 60,
    minHeight: 60,
  },
  icon: {
    marginLeft: -1,
    marginTop: -1,
    width: '5vh',
    height: '5vh',
    maxWidth: 60,
    maxHeight: 60,
    minWidth: 30,
    minHeight: 30,
  },
  form: {
    width: '100%',
    flex: 1,
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    color: 'gray',
    fontSize: 14,
    cursor: 'pointer',
    width: '100%',
    textAlign: 'right',
    marginTop: 10,
  },
  select: {
    width: '100%',
  },
  selectHorizontal: {
    width: '19vw',
  },
  buttonHorizontal: {
    color: 'conrflowerblue',
  },
  button: { width: '100%', color: 'conrflowerblue' },

  '@media screen and (max-width: 768px)': {
    paper: {
      minHeight: 'unset',
      marginTop: 10,
      marginBottom: 10,
      width: '80vw',
    },
    paperHorizontal: {
      flexDirection: 'column',
      paddingTop: 20,
      paddingBottom: 20,
      width: '90vw',
    },
    selectHorizontal: {
      width: '70vw',
      marginBottom: 20,
    },
  },
});

function SearchPanel({ home, onSearch }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const lowHeight = useMediaQuery('(max-height:640px)');
  const { searchData, updateSearchData, updateDoctorList } =
    useContext(DoctorListContext);

  const [proficiencies, setProficiencies] = useState();

  useEffect(() => {
    if (home) {
      updateDoctorList(null);
    }

    getProficiencyData();
  }, []);

  const getProficiencyData = () => {
    axiosGet(`${authURL}/proficiencies`)
      .then(({ data }) =>
        setProficiencies(
          data.proficiencies
            .slice(1)
            .map((p) => ({ id: p._id, label: p.text })),
        ),
      )
      .catch((err) => enqueueSnackbar(err, { variant: 'error' }));
  };

  const handleSearchClicked = () => {
    if (!searchData.proficiency) {
      enqueueSnackbar('Lütfen branş seçiniz', { variant: 'warning' });
      return;
    }

    if (!searchData.city) {
      enqueueSnackbar('Lütfen şehir seçiniz', { variant: 'warning' });
      return;
    }

    if (onSearch) onSearch();
    else navigate('/search');
  };

  const handleInquiriesClicked = () => {
    if (isAccessTokenValid()) {
      window.open(`${INQUIRY_HOST_ADDRESS}/list`, '_blank');
    } else {
      window.open(INQUIRY_HOST_ADDRESS, '_blank');
    }
  };

  return (
    <Paper
      elevation={3}
      className={home ? classes.paper : classes.paperHorizontal}>
      {home && (
        <>
          <Avatar className={classes.avatar}>
            <FaNotesMedical className={classes.icon} />
          </Avatar>

          <div className={classes.form}>
            {!proficiencies && <CircularProgress />}
            {proficiencies && (
              <>
                <Autocomplete
                  disablePortal
                  className={classes.select}
                  options={proficiencies}
                  value={searchData.proficiency}
                  isOptionEqualToValue={(opt, val) => opt.id === val.id}
                  onChange={(_event, newValue) =>
                    updateSearchData({ ...searchData, proficiency: newValue })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('selectProficiency')}
                      margin={lowHeight ? 'dense' : 'normal'}
                      size={lowHeight ? 'small' : 'medium'}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                <Autocomplete
                  disablePortal
                  className={classes.select}
                  options={cities}
                  value={searchData.city}
                  isOptionEqualToValue={(opt, val) => opt.id === val.id}
                  onChange={(_event, newValue) =>
                    updateSearchData({ ...searchData, city: newValue })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('selectCity')}
                      margin={lowHeight ? 'dense' : 'normal'}
                      size={lowHeight ? 'small' : 'medium'}
                    />
                  )}
                />
                <Button
                  size={lowHeight ? 'small' : 'medium'}
                  onClick={handleSearchClicked}
                  className={classes.button}
                  sx={{ mt: 1.5, mb: 1 }}
                  variant='contained'
                  startIcon={<MdSearch />}>
                  {t('searchForADoctor')}
                </Button>

                <Stack
                  flexDirection='row'
                  justifyContent='space-between'
                  sx={{ width: '100%' }}>
                  <div
                    onClick={handleInquiriesClicked}
                    className={classes.link}
                    style={{ textAlign: 'left' }}>
                    {t('myInquiries')}
                  </div>

                  {DR_HOST_ADDRESS && (
                    <div
                      onClick={() => window.open(DR_HOST_ADDRESS, '_blank')}
                      className={classes.link}>
                      {t('areYouDoctor')}
                    </div>
                  )}
                </Stack>
              </>
            )}
          </div>
        </>
      )}
      {!home && (
        <>
          {!proficiencies && <CircularProgress />}
          {proficiencies && (
            <>
              <Autocomplete
                disablePortal
                className={classes.selectHorizontal}
                options={proficiencies}
                value={searchData.proficiency}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                onChange={(_event, newValue) =>
                  updateSearchData({ ...searchData, proficiency: newValue })
                }
                renderInput={(params) => (
                  <TextField
                    className={classes.textInput}
                    {...params}
                    label={t('selectProficiency')}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                className={classes.selectHorizontal}
                options={cities}
                value={searchData.city}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                onChange={(_event, newValue) =>
                  updateSearchData({ ...searchData, city: newValue })
                }
                renderInput={(params) => (
                  <TextField
                    className={classes.textInput}
                    {...params}
                    label={t('selectCity')}
                  />
                )}
              />
              <Button
                onClick={handleSearchClicked}
                className={classes.buttonHorizontal}
                variant='contained'
                startIcon={<MdSearch />}>
                {t('searchForADoctor')}
              </Button>
            </>
          )}
        </>
      )}
    </Paper>
  );
}

export default SearchPanel;
