import { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
} from '@mui/material';
import {
  FiZoomIn,
  FiZoomOut,
  FiChevronLeft,
  FiChevronRight,
  FiExternalLink,
} from 'react-icons/fi';
import { GrPowerReset } from 'react-icons/gr';
import { pdfjs, Document, Page } from 'react-pdf';
import { urlBaseName } from '../../config';
import { t2 } from '../../utils/Language';

pdfjs.GlobalWorkerOptions.workerSrc = `${urlBaseName}/pdf.worker.min.4.4.168.js`;

const useStyles = createUseStyles({
  mainContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  zoomContainer: {
    width: '100%',
    height: '100%',
  },
  zoomContent: {
    width: '100%',
    height: '100%',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  buttonContainer: {
    position: 'absolute',
    left: 10,
    top: 10,
    zIndex: 999,
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 999,
  },
  buttons: {
    background: 'lightgrey',
    borderRadius: 5,
    cursor: 'pointer',
    color: 'black',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  zoomButtons: {
    marginRight: 12,
  },
  image: { margin: 'auto', maxWidth: '100%', maxHeight: '100%' },
  pageNum: {
    backgroundColor: 'lightgrey',
    padding: 5,
    borderRadius: 5,
    userSelect: 'none',
  },
});

export default function PdfViewer({ source }) {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const canvasRef = useRef();
  const [imageData, setImageData] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (err) => {
    console.error('onDocumentLoadError', err);
  };

  const onPageRenderSuccess = (page) => {
    const canvasEl = document.getElementsByClassName(
      canvasRef.current.className,
    )[0];
    setImageData(canvasEl.toDataURL('image/jpeg'));
  };

  const nextPage = () => {
    setImageData(null);
    setCurrentPage((p) => p + 1);
  };

  const prevPage = () => {
    setImageData(null);
    setCurrentPage((p) => p - 1);
  };

  const openInNewTab = () => window.open(source, '_blank');

  return (
    <>
      <TransformWrapper zoomAnimation={{ disabled: true }}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <div className={classes.mainContainer}>
            <div className={classes.buttonContainer}>
              <Tooltip title={t2.zoomIn}>
                <IconButton
                  className={`${classes.buttons} ${classes.zoomButtons}`}
                  onClick={() => zoomIn()}>
                  <FiZoomIn />
                </IconButton>
              </Tooltip>
              <Tooltip title={t2.zoomOut}>
                <IconButton
                  className={`${classes.buttons} ${classes.zoomButtons}`}
                  onClick={() => zoomOut()}>
                  <FiZoomOut />
                </IconButton>
              </Tooltip>
              <Tooltip title={t2.resetZoom}>
                <IconButton
                  className={`${classes.buttons} ${classes.zoomButtons}`}
                  onClick={() => resetTransform()}>
                  <GrPowerReset />
                </IconButton>
              </Tooltip>
              <Tooltip title={t2.openInNewTab}>
                <IconButton
                  className={`${classes.buttons} ${classes.zoomButtons}`}
                  onClick={openInNewTab}>
                  <FiExternalLink />
                </IconButton>
              </Tooltip>
            </div>

            <div className={classes.bottomContainer}>
              <Tooltip title={t2.prevPage}>
                <span>
                  <IconButton
                    className={classes.buttons}
                    onClick={() => {
                      prevPage();
                      resetTransform(0);
                    }}
                    disabled={currentPage === 1}>
                    <FiChevronLeft />
                  </IconButton>
                </span>
              </Tooltip>
              {numPages > 0 && (
                <Typography className={classes.pageNum} disabled>
                  {currentPage}/{numPages}
                </Typography>
              )}
              <Tooltip title={t2.nextPage}>
                <span>
                  <IconButton
                    className={classes.buttons}
                    onClick={() => {
                      nextPage();
                      resetTransform(0);
                    }}
                    disabled={currentPage === numPages}>
                    <FiChevronRight />
                  </IconButton>
                </span>
              </Tooltip>
            </div>

            <TransformComponent
              wrapperClass={classes.zoomContainer}
              contentClass={classes.zoomContent}>
              {!imageData && (
                <CircularProgress style={{ margin: 'auto', color: 'white' }} />
              )}
              {imageData && (
                <img className={classes.image} src={imageData} alt='Page' />
              )}
            </TransformComponent>
          </div>
        )}
      </TransformWrapper>

      <div style={{ display: 'none' }}>
        <Document
          file={{ url: source }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          renderMode='canvas'>
          <Page
            canvasRef={canvasRef}
            pageNumber={currentPage}
            onRenderSuccess={onPageRenderSuccess}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            height={2048}
          />
        </Document>
      </div>
    </>
  );
}
