import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
  Typography,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import { BsTrash } from 'react-icons/bs';
import { MdOutlinePermMedia, MdNotificationsNone } from 'react-icons/md';
import { TbMessageOff } from 'react-icons/tb';
import {
  getLocalizedDateString,
  INQUIRY_STATE_FINALIZED,
} from '../../utils/utils';
import { t2 } from '../../utils/Language';

const useStyles = createUseStyles({
  table: {
    minWidth: 650,
    tableLayout: 'fixed',
  },
  date: {
    width: 120,
    paddingLeft: 20,
  },
  doctorTitle: {
    width: '25%',
  },
  complaint: {
    width: '70%',
  },
  badgeContainer: {
    width: 140,
    cursor: 'default',
    padding: 0,
    paddingRight: 25,
  },
  badge: {
    marginRight: 5,
    color: '#aaa',
  },
  deleteButton: {
    marginLeft: 15,
    cursor: 'pointer',
    color: '#bbb',
    transition: 'all 0.1s linear',
    '&:hover': {
      transform: 'scale(1.1)',
      color: 'red',
    },
  },
  text: {
    width: '100%',
    fontSize: 14,
  },
  '@media screen and (max-width: 950px)': {
    date: {
      width: 70,
      paddingLeft: 10,
    },
    doctorTitle: {
      width: '20%',
      paddingRight: 0,
    },
    text: {
      width: '100%',
      fontSize: 12,
      textWrap: 'wrap',
    },
    badgeContainer: {
      width: 120,
      paddingRight: 10,
    },
  },
});

function ToolTippedCell({ text, className }) {
  return (
    <Tooltip
      title={text}
      enterDelay={500}
      enterNextDelay={500}
      placement='bottom-start'>
      <Typography noWrap className={className}>
        {text}
      </Typography>
    </Tooltip>
  );
}

function InquiryListTable({ inquiryData, handleClick, handleDelete }) {
  const classes = useStyles();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedInquiryIdx, setSelectedInquiryIdx] = useState(null);

  const openDeleteDialog = (idx, event) => {
    event.stopPropagation();
    setSelectedInquiryIdx(idx);
    setDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
    setSelectedInquiryIdx(null);
  };

  const confirmDelete = () => {
    if (selectedInquiryIdx !== null) {
      handleDelete(selectedInquiryIdx);
    }
    closeDeleteDialog();
  };

  return (
    <>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.date}>{t2.date}</TableCell>
              <TableCell align='left' className={classes.doctorTitle}>
                {t2.doctor}
              </TableCell>
              <TableCell align='left' className={classes.complaint}>
                {t2.yourComplaint}
              </TableCell>
              <TableCell className={classes.badgeContainer}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inquiryData &&
              inquiryData.map((row, idx) => (
                <TableRow
                  key={row._id}
                  onClick={() => handleClick(row._id, row.doctor.id)}
                  style={{ cursor: 'pointer' }}>
                  <TableCell
                    className={`${classes.date} ${classes.text}`}
                    component='th'
                    scope='row'>
                    {getLocalizedDateString(row.timestamp)}
                  </TableCell>

                  <TableCell className={classes.doctorTitle}>
                    <ToolTippedCell
                      text={row.doctor.nameSurname}
                      className={classes.text}
                    />
                  </TableCell>

                  <TableCell className={classes.complaint}>
                    <ToolTippedCell
                      text={row.complaint}
                      className={classes.text}
                    />
                  </TableCell>

                  <TableCell
                    className={classes.badgeContainer}
                    align='right'
                    onClick={(e) => e.stopPropagation()}>
                    {row.new && (
                      <Tooltip title={t2.newMessage}>
                        <div style={{ display: 'inline' }}>
                          <MdNotificationsNone
                            className={classes.badge}
                            color='Tomato'
                            size={'1.4em'}
                          />
                        </div>
                      </Tooltip>
                    )}

                    {row.inquiryState === INQUIRY_STATE_FINALIZED && (
                      <Tooltip title={t2.inquiryFinalizedShort}>
                        <div style={{ display: 'inline' }}>
                          <TbMessageOff
                            className={classes.badge}
                            size={'1.4em'}
                          />
                        </div>
                      </Tooltip>
                    )}

                    {row.mediaCount > 0 && (
                      <Tooltip title={t2.hasMedia}>
                        <div style={{ display: 'inline' }}>
                          <MdOutlinePermMedia
                            className={classes.badge}
                            size={'1.4em'}
                          />
                        </div>
                      </Tooltip>
                    )}

                    <Tooltip title={t2.deleteInquiry}>
                      <div style={{ display: 'inline' }}>
                        <BsTrash
                          className={classes.deleteButton}
                          onClick={(e) => openDeleteDialog(idx, e)}
                          size={'1.4em'}
                        />
                      </div>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={deleteDialog}
        onClose={closeDeleteDialog}
        style={{ background: 'rgba(0,0,0,0.2)' }}>
        <DialogTitle id='alert-dialog-title'>{t2.areYouSure}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t2.aprrovalToDelete}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color='primary'>
            {t2.cancel}
          </Button>
          <Button onClick={confirmDelete} color='error'>
            {t2.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InquiryListTable;
