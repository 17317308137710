import { images } from '../constants';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './header.css';
import SearchPanel from '../SearchPanel';
import { t } from '../../utils/Language';

const Header = () => {
  return (
    <div id='home' className='app_header'>
      <Carousel
        emulateTouch
        interval={30000}
        transitionTime={1750}
        autoPlay
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        showArrows={true}
        stopOnHover={false}
        useKeyboardArrows>
        <div className='carousel_img_container'>
          <img className='carousel_img' src={images.slider_1} alt='' />
          <div className='carousel_text_bg'>
            <p className='carousel-title'>{t('sliderHeader_1')} </p>
            <p className='carousel_text'>{t('sliderText_1')}</p>
          </div>
        </div>
        <div className='carousel_img_container'>
          <img className='carousel_img' src={images.slider_2} alt='' />
          <div className='carousel_text_bg'>
            <p className='carousel-title'>{t('sliderHeader_2')} </p>
            <p className='carousel_text'>{t('sliderText_2')}</p>
          </div>
        </div>
        <div className='carousel_img_container'>
          <img className='carousel_img' src={images.slider_3} alt='' />
          <div className='carousel_text_bg'>
            <p className='carousel-title'>{t('sliderHeader_3')} </p>
            <p className='carousel_text'>{t('sliderText_3')}</p>
          </div>
        </div>
        <div className='carousel_img_container'>
          <img className='carousel_img' src={images.slider_4} alt='' />
          <div className='carousel_text_bg'>
            <p className='carousel-title'>{t('sliderHeader_4')} </p>
            <p className='carousel_text'>{t('sliderText_4')}</p>
          </div>
        </div>
      </Carousel>

      <div className='search_paper_container'>
        <SearchPanel home />
      </div>
    </div>
  );
};
export default Header;
