import { useEffect, useState } from 'react';
import { FaRegArrowAltCircleUp } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import { Outlet, useLocation } from 'react-router-dom';
import NavBar from '../components/Inquiry/InquiryNavBar/InquiryNavBar';
import Footer from '../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import { PRODUCT_NAME } from '../config';

const useStyles = createUseStyles({
  root: {
    minHeight: '100vh',
    width: '100vw',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    rowGap: 10,
    bottom: 30,
    right: 30,
    width: 80,
    height: 120,
    zIndex: 999,
  },
  icon: {
    cursor: 'pointer',
    color: 'cornflowerblue',
    opacity: 0.7,
    width: 40,
    height: 40,
    transition: 'all 100ms linear',
    '&:hover': {
      opacity: 1,
      width: 50,
      height: 50,
    },
  },
  '@media screen and (max-width: 768px)': {
    buttonContainer: {
      right: 8,
    },
  },
});

function InquiryPageBase() {
  const classes = useStyles();

  const [scrollButton, setScrollButton] = useState(false);

  const location = useLocation();
  const isLoginPage =
    location.pathname === '/inquiry' || location.pathname === '/inquiry/';

  useEffect(() => {
    const handleScroll = () => {
      const screenHeight = window.screen.height;

      if (window.scrollY > screenHeight) setScrollButton(true);
      else setScrollButton(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{PRODUCT_NAME}</title>
      </Helmet>
      <div className={classes.root}>
        <NavBar disableHeaderOptions={isLoginPage} />
        <div className={classes.body}>
          <Outlet />
        </div>
        <Footer />
        {scrollButton && (
          <div className={classes.buttonContainer}>
            <FaRegArrowAltCircleUp
              className={classes.icon}
              onClick={() =>
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
              }
            />
          </div>
        )}
      </div>
    </>
  );
}

export default InquiryPageBase;
