import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { createUseStyles } from 'react-jss';
import { IconButton, Tooltip } from '@mui/material';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import { GrPowerReset } from 'react-icons/gr';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { t2 } from '../../utils/Language';

const useStyles = createUseStyles({
  mainContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  zoomContainer: {
    width: '100%',
    height: '100%',
  },
  zoomContent: {
    width: '100%',
    height: '100%',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  image: { margin: 'auto', maxWidth: '100%', maxHeight: '100%' },
  zoomButtonContainer: {
    position: 'absolute',
    left: 10,
    top: 10,
    zIndex: 999,
  },
  zoomButtons: {
    background: 'lightgrey',
    borderRadius: 5,
    marginRight: 12,
    cursor: 'pointer',
    color: 'black',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  removeButton: {
    color: '#e30017',
  },
});

export default function ZoomPanImage({ src, onFullscreen, onRemove }) {
  const classes = useStyles();

  return (
    <TransformWrapper>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <div className={classes.mainContainer}>
          <div className={classes.zoomButtonContainer}>
            <Tooltip title={t2.zoomIn}>
              <IconButton
                className={classes.zoomButtons}
                onClick={() => zoomIn()}>
                <FiZoomIn />
              </IconButton>
            </Tooltip>
            <Tooltip title={t2.zoomOut}>
              <IconButton
                className={classes.zoomButtons}
                onClick={() => zoomOut()}>
                <FiZoomOut />
              </IconButton>
            </Tooltip>
            <Tooltip title={t2.resetZoom}>
              <IconButton
                className={classes.zoomButtons}
                onClick={() => resetTransform()}>
                <GrPowerReset />
              </IconButton>
            </Tooltip>
            {onFullscreen && (
              <Tooltip title={t2.fullScreen}>
                <IconButton
                  className={classes.zoomButtons}
                  onClick={onFullscreen}>
                  <BsArrowsFullscreen />
                </IconButton>
              </Tooltip>
            )}
            {onRemove && (
              <Tooltip title={t2.remove}>
                <IconButton
                  className={`${classes.zoomButtons} ${classes.removeButton}`}
                  onClick={onRemove}>
                  <IoRemoveCircleOutline />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <TransformComponent
            wrapperClass={classes.zoomContainer}
            contentClass={classes.zoomContent}>
            <img className={classes.image} src={src} alt='Patient media' />
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  );
}
