import { useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import defaultThumbnail from '../../assets/pdfthumbnail.jpg';
import { createFileFromPath } from '../../utils/utils';
import { urlBaseName } from '../../config';

pdfjs.GlobalWorkerOptions.workerSrc = `${urlBaseName}/pdf.worker.min.4.4.168.js`;

export default function PdfThumbnailGenerator({
  files,
  onThumbnailsGenerated,
}) {
  const [file, setFile] = useState();
  const canvasRef = useRef();
  const fileIdx = useRef(0);
  const thumbnailDataList = useRef([]);
  const defaultThumbnailFile = useRef();

  useEffect(() => {
    createFileFromPath(
      defaultThumbnail,
      'defaultPdfThumbnail.jpeg',
      'image/jpeg',
    ).then((file) => (defaultThumbnailFile.current = file));
  }, []);

  useEffect(() => {
    thumbnailDataList.current = [];
    fileIdx.current = 0;

    if (files.length > 0) setFile(files[0]);
  }, [files]);

  const onPageRenderSuccess = async (page) => {
    const canvasEl = document.getElementsByClassName(
      canvasRef.current.className,
    )[0];

    const fileName = files[fileIdx.current].name;
    let thumbnail = defaultThumbnailFile.current;

    const blob = await new Promise((resolve) =>
      canvasEl.toBlob(resolve, 'image/jpeg'),
    );

    if (blob)
      thumbnail = new File([blob], `${fileName}.jpeg`, { type: 'image/jpeg' });

    thumbnailDataList.current[fileIdx.current] = {
      fileName,
      thumbnail,
    };

    renderNextDocument();
  };

  const onDocumentLoadError = (err) => {
    console.error('onDocumentLoadError', err);

    thumbnailDataList.current[fileIdx.current] = {
      fileName: files[fileIdx.current].name,
      thumbnail: defaultThumbnailFile.current,
    };

    renderNextDocument();
  };

  const onPageRenderError = (err) => {
    console.error('onPageRenderError', err);

    thumbnailDataList.current[fileIdx.current] = {
      fileName: files[fileIdx.current].name,
      thumbnail: defaultThumbnailFile.current,
    };

    renderNextDocument();
  };

  const renderNextDocument = () => {
    fileIdx.current++;
    if (fileIdx.current < files.length) {
      setFile(files[fileIdx.current]);
    } else {
      onThumbnailsGenerated(thumbnailDataList.current);
    }
  };

  return (
    <div style={{ display: 'none' }}>
      {file && (
        <Document
          file={file}
          onLoadError={onDocumentLoadError}
          renderMode='canvas'>
          <Page
            canvasRef={canvasRef}
            pageNumber={1}
            onRenderSuccess={onPageRenderSuccess}
            onRenderError={onPageRenderError}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            width={500}
          />
        </Document>
      )}
    </div>
  );
}
