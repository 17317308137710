import axios from 'axios';
import { BUILD_LANGUAGE, BUILD_LOCALE, INQUIRY_HOST_ADDRESS } from '../config';
import { t } from './Language';
import { jwtDecode } from 'jwt-decode';

export const DB_STATE_ACTIVE = 'DB_STATE_ACTIVE';
export const DB_STATE_PENDING = 'DB_STATE_PENDING';
export const DB_STATE_PASSIVE = 'DB_STATE_PASSIVE';
const AXIOS_REQUEST_TIMEOUT = 5000;

export const axiosGet = (url, isAuthenticated, config) => {
  const urlObj = new URL(url);
  if (!urlObj.searchParams.has('lang')) {
    urlObj.searchParams.append('lang', BUILD_LANGUAGE);
  }

  return axios.get(urlObj.toString(), {
    headers: isAuthenticated
      ? {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      : null,
    timeout: AXIOS_REQUEST_TIMEOUT,
    ...config,
  });
};

export const axiosPost = (url, body, isAuthenticated, config) => {
  return axios.post(
    url,
    { ...body, lang: BUILD_LANGUAGE },
    {
      headers: isAuthenticated
        ? {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        : null,
      timeout: AXIOS_REQUEST_TIMEOUT,
      ...config,
    },
  );
};

export const axiosUpload = (url, body, isAuthenticated, config) => {
  body.append('lang', BUILD_LANGUAGE);

  return axios.post(url, body, {
    headers: isAuthenticated
      ? {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      : null,
    timeout: 180000,
    ...config,
  });
};

export const axiosPut = (url, body, isAuthenticated, config) => {
  return axios.put(
    url,
    { ...body, lang: BUILD_LANGUAGE },
    {
      headers: isAuthenticated
        ? {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        : null,
      timeout: AXIOS_REQUEST_TIMEOUT,
      ...config,
    },
  );
};

export const axiosPatch = (url, body, isAuthenticated, config) => {
  return axios.patch(
    url,
    { ...body, lang: BUILD_LANGUAGE },
    {
      headers: isAuthenticated
        ? {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        : null,
      timeout: AXIOS_REQUEST_TIMEOUT,
      ...config,
    },
  );
};

export const axiosDelete = (url, isAuthenticated, config) => {
  return axios.delete(url, {
    headers: isAuthenticated
      ? {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      : null,
    timeout: AXIOS_REQUEST_TIMEOUT,
    data: { lang: BUILD_LANGUAGE },
    ...config,
  });
};
export const getLocalizedTimestamp = (date) =>
  date ? new Date(date).getTime() : null;
export const getLocalizedDateString = (timestamp) =>
  timestamp ? new Date(timestamp).toLocaleDateString(BUILD_LOCALE) : null; //.replaceAll(".", "-");
export const getLocalizedDateTimeString = (timestamp) =>
  timestamp ? new Date(timestamp).toLocaleString(BUILD_LOCALE) : null; //.replaceAll(".", "-");

export const errorHandler = (err, msg) => {
  let message = msg ?? 'Unknown error!';

  console.error(err);

  if (err.response) {
    if (err.response.data?.message) {
      if (
        Array.isArray(err.response.data.message) &&
        err.response.data.message.length > 1
      ) {
        message = err.response.data.message.join('\n');
      } else {
        message = err.response.data.message;
      }
    } else {
      message = err.message;
    }

    if (err.response.status === 401) {
      localStorage.clear();
      message = t('reloginToInquire'); // TODO: this snackbar message disappears immediately because of the navigate

      if (window.location.pathname !== '/') {
        // since we cannot use navigate here...
        window.location.replace(INQUIRY_HOST_ADDRESS);
      }
    }

    return message;
  } else if (err.message) {
    return `${err.message} (no response!)`; // for developers TODO: remove before build
  }
};

export const cities = [
  { id: 1, label: 'İSTANBUL' },
  { id: 2, label: 'ANKARA' },
  { id: 3, label: 'İZMİR' },
  { id: 4, label: 'ADANA' },
  { id: 5, label: 'ADIYAMAN' },
  { id: 6, label: 'AFYONKARAHİSAR' },
  { id: 7, label: 'AĞRI' },
  { id: 8, label: 'AMASYA' },
  { id: 9, label: 'ANTALYA' },
  { id: 10, label: 'ARTVİN' },
  { id: 11, label: 'AYDIN' },
  { id: 12, label: 'BALIKESİR' },
  { id: 13, label: 'BİLECİK' },
  { id: 14, label: 'BİNGÖL' },
  { id: 15, label: 'BİTLİS' },
  { id: 16, label: 'BOLU' },
  { id: 17, label: 'BURDUR' },
  { id: 18, label: 'BURSA' },
  { id: 19, label: 'ÇANAKKALE' },
  { id: 20, label: 'ÇANKIRI' },
  { id: 21, label: 'ÇORUM' },
  { id: 22, label: 'DENİZLİ' },
  { id: 23, label: 'DİYARBAKIR' },
  { id: 24, label: 'EDİRNE' },
  { id: 25, label: 'ELAZIĞ' },
  { id: 26, label: 'ERZİNCAN' },
  { id: 27, label: 'ERZURUM' },
  { id: 28, label: 'ESKİŞEHİR' },
  { id: 29, label: 'GAZİANTEP' },
  { id: 30, label: 'GİRESUN' },
  { id: 31, label: 'GÜMÜŞHANE' },
  { id: 32, label: 'HAKKARİ' },
  { id: 33, label: 'HATAY' },
  { id: 34, label: 'ISPARTA' },
  { id: 35, label: 'MERSİN' },
  { id: 36, label: 'KARS' },
  { id: 37, label: 'KASTAMONU' },
  { id: 38, label: 'KAYSERİ' },
  { id: 39, label: 'KIRKLARELİ' },
  { id: 40, label: 'KIRŞEHİR' },
  { id: 41, label: 'KOCAELİ' },
  { id: 42, label: 'KONYA' },
  { id: 43, label: 'KÜTAHYA' },
  { id: 44, label: 'MALATYA' },
  { id: 45, label: 'MANİSA' },
  { id: 46, label: 'KAHRAMANMARAŞ' },
  { id: 47, label: 'MARDİN' },
  { id: 48, label: 'MUĞLA' },
  { id: 49, label: 'MUŞ' },
  { id: 50, label: 'NEVŞEHİR' },
  { id: 51, label: 'NİĞDE' },
  { id: 52, label: 'ORDU' },
  { id: 53, label: 'RİZE' },
  { id: 54, label: 'SAKARYA' },
  { id: 55, label: 'SAMSUN' },
  { id: 56, label: 'SİİRT' },
  { id: 57, label: 'SİNOP' },
  { id: 58, label: 'SİVAS' },
  { id: 59, label: 'TEKİRDAĞ' },
  { id: 60, label: 'TOKAT' },
  { id: 61, label: 'TRABZON' },
  { id: 62, label: 'TUNCELİ' },
  { id: 63, label: 'ŞANLIURFA' },
  { id: 64, label: 'UŞAK' },
  { id: 65, label: 'VAN' },
  { id: 66, label: 'YOZGAT' },
  { id: 67, label: 'ZONGULDAK' },
  { id: 68, label: 'AKSARAY' },
  { id: 69, label: 'BAYBURT' },
  { id: 70, label: 'KARAMAN' },
  { id: 71, label: 'KIRIKKALE' },
  { id: 72, label: 'BATMAN' },
  { id: 73, label: 'ŞIRNAK' },
  { id: 74, label: 'BARTIN' },
  { id: 75, label: 'ARDAHAN' },
  { id: 76, label: 'IĞDIR' },
  { id: 77, label: 'YALOVA' },
  { id: 78, label: 'KARABüK' },
  { id: 79, label: 'KİLİS' },
  { id: 80, label: 'OSMANİYE' },
  { id: 81, label: 'DÜZCE' },
];

export const RECORD_PATIENT_PICTURE = 'RECORD_PATIENT_PICTURE';
export const RECORD_PATIENT_VIDEO = 'RECORD_PATIENT_VIDEO';
export const RECORD_PATIENT_DOCUMENT = 'RECORD_PATIENT_DOCUMENT';
export const INQUIRY_STATE_ACTIVE = 'INQUIRY_STATE_ACTIVE';
export const INQUIRY_STATE_FINALIZED = 'INQUIRY_STATE_FINALIZED';

export const getRecordType = (file) => {
  if (
    file.type === 'video/mp4' ||
    file.type === 'video/mov' ||
    file.type === 'video/quicktime' ||
    file.type === 'video'
  ) {
    return RECORD_PATIENT_VIDEO;
  } else if (
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/heif' ||
    file.type === 'image/heic' ||
    file.type === 'image'
  ) {
    return RECORD_PATIENT_PICTURE;
  } else if (file.type === 'application/pdf') {
    return RECORD_PATIENT_DOCUMENT;
  }
};

export const acceptedFileTypes = [
  'mov',
  'avi',
  'mp4',
  'jpg',
  'jpeg',
  'png',
  'heif',
  'heic',
  'pdf',
];

export const acceptedFileMimeTypes = {
  'image/png': ['.png', '.jpg', '.jpeg', '.heif', '.heic'],
  // 'image/jpeg': ['.jpg', '.jpeg'],
  // 'image/heic': ['.heif', '.heic'],
  'video/quicktime': ['.mp4', '.mov', '.avi'],
  // 'video/mp4': ['.mp4'],
  // 'video/x-msvideo': ['.avi'],
  'application/pdf': ['.pdf'],
};

export async function createFileFromPath(path, name, type) {
  const response = await fetch(path);
  const data = await response.blob();
  const metadata = {
    type: type,
  };
  return new File([data], name, metadata);
}

export const isValidTurkishMobilePhoneNumber = (phone) => {
  if (
    phone &&
    phone.slice(0, 2) === '90' &&
    phone.length === 12 &&
    !isNaN(phone)
  )
    return true;

  return false;
};

export const isAccessTokenValid = () => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) return false;

  const token = jwtDecode(accessToken);
  if (token.expires * 1000 < Date.now() - 300000) {
    return false;
  }

  return true;
};
