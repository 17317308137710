import { Modal, Tooltip, IconButton } from '@mui/material';
import { FiX } from 'react-icons/fi';
import { createUseStyles } from 'react-jss';
import ZoomPanImage from './ZoomPanImage';

const useStyles = createUseStyles({
  modal: { background: 'rgba(0,0,0,0.9)' },
  mainContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIcon: {
    fontSize: 26,
    position: 'absolute',
    right: 10,
    top: 10,
    color: '#e30017',
    background: 'lightgrey',
    borderRadius: 5,
    cursor: 'pointer',
    transition: 'background 200ms linear',
    zIndex: 999,
    '&:hover': {
      background: 'white',
    },
  },
});

export default function ImageModal({ open, src, handleClose }) {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <div className={classes.mainContainer}>
        <Tooltip title='Kapat'>
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <FiX />
          </IconButton>
        </Tooltip>
        <ZoomPanImage src={src} />
      </div>
    </Modal>
  );
}
