import { useState } from 'react';
import { IoIosMenu } from 'react-icons/io';
import './InquiryNavBar.css';
import { useNavigate } from 'react-router-dom';
import { t } from '../../../utils/Language';
import { BUILD_LANGUAGE, PRODUCT_NAME } from '../../../config';
import { MdLogout } from 'react-icons/md';

const NavBar = ({ style, disableHeaderOptions }) => {
  const navigate = useNavigate();
  const [menuExpanded, setMenuExpanded] = useState(false);

  let headerText;
  const name = localStorage.getItem('name');
  const surname = localStorage.getItem('surname');
  const phoneNumber = localStorage.getItem('phoneNumber');

  if (name && surname) headerText = `${name} ${surname}`;
  else headerText = phoneNumber;

  const doLogout = () => {
    localStorage.clear();
    document.removeEventListener('canLogout', logoutEventListener);
    navigate('/');
  };

  const logoutEventListener = (e) => {
    doLogout();
  };

  const handlelogout = () => {
    document.addEventListener('canLogout', logoutEventListener);

    const res = document.dispatchEvent(
      new CustomEvent('willLogout', { cancelable: true }),
    );
    if (!res) {
      console.error('logout canceled');
      return;
    } else {
      doLogout();
    }
  };

  const links = [
    <li
      key='link_home'
      className='navbar_menu_title'
      onClick={() => navigate('/#home')}>
      {t('homepage').toLocaleUpperCase(BUILD_LANGUAGE)}
    </li>,
  ];

  if (!disableHeaderOptions) {
    links.push(
      <li
        key='link_list'
        className='navbar_menu_title'
        onClick={() => navigate('/inquiry/list')}>
        {t('inquiries').toLocaleUpperCase(BUILD_LANGUAGE)}
      </li>,
    );

    links.push(
      <li
        key='link_logout'
        className='navbar_menu_title'
        onClick={handlelogout}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {t('logout').toLocaleUpperCase(BUILD_LANGUAGE)}

          <MdLogout style={{ marginLeft: 10 }} />
        </div>
      </li>,
    );
  }

  return (
    <>
      <nav style={style} className='app__navbar'>
        <div className='app__navbar_row0'>
          <div className='app__navbar-logo'>
            <h1 id='logo' onClick={() => navigate('/#home')}>
              {PRODUCT_NAME}
            </h1>
          </div>

          <div className='app__navbar-headerText'>{headerText}</div>
          <div className='app__navbar_menu_spreaded'>
            <ul className='app__navbar-links'>{links}</ul>
          </div>
          {!disableHeaderOptions && (
            <div className='app__navbar_menu_button'>
              <IoIosMenu
                size={50}
                color='white'
                onClick={() => setMenuExpanded((e) => !e)}
              />
            </div>
          )}
        </div>
        <div className='app__navbar_row1'>
          <ul
            className={`app__navbar-links-expanded ${
              menuExpanded ? '' : 'app__navbar-menu-collapsed'
            }`}
            onClick={() => setMenuExpanded(false)}>
            {links}
          </ul>
        </div>
        <div className='app__navbar_row1'></div>
      </nav>
    </>
  );
};

export default NavBar;
