import { createUseStyles } from 'react-jss';
import bgImage from '../../assets/background-gradient.jpg';
import LoginPanel from './LoginPanel';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#E5E6FA',
  },
  bgImg: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    opacity: 0.4,
    zIndex: 0,
  },
});

function Login() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={bgImage} className={classes.bgImg} alt='bg' />

      <LoginPanel />
    </div>
  );
}

export default Login;
