import { Card, CardContent, CircularProgress, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Helmet } from 'react-helmet';
import bgImage from '../../assets/background-gradient.jpg';
import NewInquiryRequest from './NewInquiryRequest.jsx';
import DoctorSimpleInfo from '../../components/DoctorSimpleInfo.jsx';
import { t } from '../../utils/Language';
import { useState } from 'react';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#E5E6FA',
    paddingTop: 20,
    paddingBottom: 10,
    zIndex: 10,
  },
  bgImg: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    opacity: 0.4,
    zIndex: 0,
  },
  card: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 10,
    padding: 10,
    zIndex: 1,
    '@media (max-width: 768px)': {
      width: '90vw',
      padding: 0,
    },
  },
});

function NewInquiry() {
  const classes = useStyles();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const doctorId = location?.state?.doctorId;

  return (
    <>
      <Helmet>
        <title>{t('newInquiryRequest')}</title>
      </Helmet>
      <div className={classes.container}>
        <img src={bgImage} className={classes.bgImg} alt='bg' />

        {loading && (
          <Stack
            justifyContent='center'
            alignItems='center'
            style={{
              position: 'fixed',
              top: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.1)',
              zIndex: 999,
            }}>
            <CircularProgress />
          </Stack>
        )}

        <DoctorSimpleInfo doctorId={doctorId} />

        <Card className={classes.card}>
          <CardContent style={{ width: '100%' }}>
            <NewInquiryRequest doctorId={doctorId} onLoading={setLoading} />
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default NewInquiry;
