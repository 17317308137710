import { createUseStyles } from 'react-jss';
import { Modal, Fade } from '@mui/material';

const useStyles = createUseStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    borderRadius: 10,
    padding: 20,
    width: '80%',
    height: '80%',
    outline: 'none',
    borderStyle: 'none',
  },
  iframe: {
    width: '100%',
    height: '100%',
    borderStyle: 'none',
  },
});

export default function SimpleIframeModal({ open, url, handleClose }) {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition={true}>
      <Fade in={open}>
        <div className={classes.paper}>
          <iframe className={classes.iframe} src={url} title='Iframe modal' />
        </div>
      </Fade>
    </Modal>
  );
}
