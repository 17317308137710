import React from 'react';
import { images } from '../constants';
import './contact.css';
import { t } from '../../utils/Language';

const Contact = () => {
  return (
    <div className='app_contact' id='contact'>
      <div className='app_contact-content'>
        <h1 className='app_contact-title'>{t('contact')}</h1>
        <div style={{ alignSelf: 'flex-start' }}>
          <p className='app_contact-text'>
            <b>{t('address')}:</b> Yıldız Teknik Üniversitesi Davutpaşa Kampüsü{' '}
            <br />
            Teknopark D2 Blok Z04, Esenler–İstanbul
            <br />
            <br />
            <b>{t('phone')}:</b> (212) 203 0808
            <br />
            <br />
            <b>{t('email')}:</b> info@dogalzeka.com.tr
          </p>
        </div>
        <div className='instagram_link'>
          <a
            target='_blank'
            rel='noreferrer noopenner'
            href='https://instagram.com/prmshastakayit'>
            <img src={images.instagram} alt='Instagram sayfası' />
          </a>
        </div>
      </div>
      <div className='app_contact-img'>
        <img src={images.medical_3} alt='G_overlay' />
      </div>
    </div>
  );
};

export default Contact;
