import { useState, useEffect, useRef } from 'react';
import dzLogo from '../../assets/dogalzeka.png';
import './footer.css';
import { useNavigate } from 'react-router-dom';
import { axiosGet } from '../../utils/utils';
import { authURL } from '../../config';
import SimpleIframeModal from '../SimpleIframeModal';
import { t } from '../../utils/Language';

const Footer = () => {
  const navigate = useNavigate();

  const kvkkUrl = useRef('');

  const [iframeOpen, setIframeOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {
    getAggrementUrls();
  }, []);

  const getAggrementUrls = () => {
    axiosGet(`${authURL}/kvkkurl`)
      .then(({ data }) => {
        kvkkUrl.current = data.url;
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className='app__footer'>
      <div className='app__footer-row0'>
        <img
          className='footer_logo'
          src={dzLogo}
          alt='footer_logo'
          onClick={() => navigate('/')}
        />
      </div>
      <div className='app__footer-row1'>
        <a
          onClick={() => {
            setIframeUrl(kvkkUrl.current);
            setIframeOpen(true);
          }}
          className='footer-link'
          target='_blank'>
          {t('kvkk')}
        </a>
      </div>

      <SimpleIframeModal
        open={iframeOpen}
        url={iframeUrl}
        handleClose={() => setIframeOpen(false)}
      />
    </div>
  );
};

export default Footer;
