import {
  useEffect,
  useState,
  useRef,
  useCallback,
  memo,
  useContext,
} from 'react';
import { CircularProgress, Typography, Paper } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useSnackbar } from 'notistack';
import SearchPanel from '../components/SearchPanel';
import DoctorCard from '../components/DoctorCard';
import { axiosGet } from '../utils/utils';
import { PRODUCT_NAME, apiURL } from '../config';
import bgImage from '../assets/background-gradient.jpg';
import InfiniteScroll from 'react-infinite-scroller';
import { DoctorListContext } from '../store/DoctorListContext';
import { t } from '../utils/Language';
import { Helmet } from 'react-helmet';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#E5E6FA',
  },
  listContainer: {
    marginTop: 10,
    paddingTop: 20,
    paddingBottom: 20,
    width: '60vw',
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'space-around',
    zIndex: 10,
  },
  bgImg: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    opacity: 0.4,
    zIndex: 0,
  },
  doctorListContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  '@media screen and (max-width: 768px)': {
    listContainer: { width: '90vw' },
    doctorListContainer: {
      justifyContent: 'space-evenly',
    },
  },
});

function DoctorList({ doctors, onSelect }) {
  const classes = useStyles();
  return (
    <div className={classes.doctorListContainer}>
      {doctors.map((doc) => (
        <DoctorCard
          key={doc.id}
          id={doc.id}
          title={`${doc.title ?? ''} ${doc.name} ${doc.surname}`}
          proficiency={doc.proficiency}
          institution={doc.institution}
          address={doc.address}
          rating={doc.rating}
          photoUrl={doc.thumbnailUrl}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
}

function InProgress() {
  return (
    <div
      key={'infinitescrollloaderkey'}
      style={{
        height: 100,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <CircularProgress />
    </div>
  );
}

const DoctorListMemoized = memo(DoctorList);

function Search() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    searchData,
    doctorList,
    meta,
    yPos,
    updateDoctorList,
    updateMeta,
    updateYPos,
  } = useContext(DoctorListContext);
  const [isLoading, setIsLoading] = useState(false);
  const yPosition = useRef(0);

  const hasMore = Boolean(meta.next);

  useEffect(() => {
    if (searchData.proficiency && searchData.city && !doctorList) {
      getDoctorList();
    }

    const handleScroll = () => {
      yPosition.current = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (doctorList?.length > 0) {
      if (yPos) {
        updateYPos(null);
        window.scrollTo({ top: yPos, left: 0, behavior: 'auto' });
      }
    }
  }, [doctorList]);

  const getDoctorList = () => {
    setIsLoading(true);
    axiosGet(
      `${apiURL}/doctors?start=0&limit=6&proficiencyId=${searchData.proficiency.id}&city=${searchData.city.label}`,
    )
      .then(({ data }) => {
        updateDoctorList(data.doctorList);
        updateMeta(data.meta);
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
      .then(() => setIsLoading(false));
  };

  const getNextUrl = () => {
    const url = meta.next;
    if (!url) return;

    axiosGet(
      `${apiURL}${url}&limit=6&proficiencyId=${searchData.proficiency.id}&city=${searchData.city.label}`,
    )
      .then(({ data }) => {
        updateDoctorList([...doctorList, ...data.doctorList]);
        updateMeta(data.meta);
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
      .then(() => setIsLoading(false));
  };

  const handleSearch = () => {
    getDoctorList();
  };

  const handleSelect = useCallback(() => {
    updateYPos(yPosition.current);
  }, []);

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{PRODUCT_NAME}</title>
      </Helmet>

      <img src={bgImage} className={classes.bgImg} alt='bg' />

      <SearchPanel horizontal onSearch={handleSearch} />

      {(isLoading || doctorList) && (
        <Paper elevation={3} className={classes.listContainer}>
          {isLoading && <InProgress />}
          {!isLoading && doctorList && doctorList.length === 0 && (
            <Typography>{t('noDoctorFound')}</Typography>
          )}
          {!isLoading && doctorList && doctorList.length > 0 && (
            <InfiniteScroll
              pageStart={0}
              loadMore={() => getNextUrl()}
              hasMore={hasMore}
              loader={<InProgress key='InProgress' />}
              initialLoad={false}
              style={{
                width: '100%',
              }}>
              <DoctorListMemoized
                key={'DoktorList'}
                doctors={doctorList}
                onSelect={handleSelect}
              />
            </InfiniteScroll>
          )}
        </Paper>
      )}
    </div>
  );
}

export default Search;
