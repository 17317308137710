import {
  Card,
  Stack,
  Typography,
  Rating,
  useMediaQuery,
  CircularProgress,
  Box,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import ProfileImage from '../assets/profile.jpg';
import { MdWhatsapp } from 'react-icons/md';
import { apiURL, GOOGLE_MAPS_API_KEY } from '../config.js';
import { useEffect, useState } from 'react';
import { axiosGet } from '../utils/utils.js';
import { enqueueSnackbar } from 'notistack';
import { t } from '../utils/Language';
import { useNavigate } from 'react-router-dom';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 10,
    zIndex: 10,
  },
  card: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 10,
    padding: 10,
    zIndex: 1,
  },
  imageContainer: {
    display: 'inline-block',
    padding: 16,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  image: {
    borderRadius: 3,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    width: 300,
    height: 300,
    objectFit: 'cover',
  },
  avatarCardInfoPane: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  nameLine: {
    flexDirection: 'row',
  },
  fieldTitle: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 16,
  },
  fieldContent: { whiteSpace: 'pre-line' },

  avatarCardAddressPane: {
    marginTop: -40,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  addressContainer: {
    textAlign: 'right',
    marginRight: 20,
    maxWidth: 300,
  },
  mapContainer: {
    width: 240,
    height: 180,
  },
  googleMap: {
    position: 'absolute',
    top: 0,
    left: 0,
    paddingTop: 5,
    paddingBottom: 5,
    padding: 10,
    margin: 10,
    backgroundColor: 'white',
    textAlign: 'center',
  },
  showBigMap: {
    fontSize: 12,
    color: '#1a73e8',
  },

  '@media screen and (max-width: 1400px)': {
    card: {
      width: '80vw',
    },
  },
  '@media screen and (max-width: 1200px)': {
    nameLine: { flexDirection: 'column' },
    avatarCardAddressPane: {
      marginTop: 0,
    },
  },
  '@media screen and (max-width: 1080px)': {
    avatarCardInfoPane: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  '@media screen and (max-width: 768px)': {
    card: {
      width: '90vw',
      flexDirection: 'column',
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      marginRight: 0,
    },
    avatarCardAddressPane: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    addressContainer: { textAlign: 'left', marginRight: 0, maxWidth: 'unset' },
    mapContainer: {
      marginTop: 16,
    },
  },
});

function DoctorSimpleInfo({ doctorId }) {
  const classes = useStyles();
  const lowWidth = useMediaQuery('(max-width:768px)');
  const [doctor, setDoctor] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getDoctor();
  }, []);

  const getDoctor = () => {
    axiosGet(`${apiURL}/doctors/${doctorId}`)
      .then(({ data }) => {
        setDoctor(data);
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  };

  const handleWhatsappClicked = () => {
    const message = `Merhaba ${doctor?.publicProfileInfo?.title ?? ''} ${
      doctor?.name
    } ${doctor?.surname}. `;

    window.open(
      `https://wa.me/${doctor.publicProfileInfo.phone}?text=${encodeURI(
        message,
      )}`,
      'mozillaTab',
    );
  };

  const handleNavigateToDoctor = () => {
    navigate(`/doctor/${doctorId}`);
  };

  const addressPane = doctor ? (
    <Stack className={classes.avatarCardAddressPane}>
      <Box className={classes.addressContainer}>
        <Typography sx={{ fontWeight: 400, fontSize: 18 }}>
          {doctor.publicProfileInfo.institution}
        </Typography>
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {doctor.publicProfileInfo.address}
        </Typography>
        <Typography>{`${
          doctor.publicProfileInfo.region
            ? `${doctor.publicProfileInfo.region}/`
            : ''
        }${doctor.publicProfileInfo.city}`}</Typography>
      </Box>
      {(doctor.publicProfileInfo.coordinates?.lng !== 0 ||
        doctor.publicProfileInfo.coordinates?.lat !== 0) && (
        <Box className={classes.mapContainer}>
          <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
            <Map
              clickableIcons={false}
              center={doctor.publicProfileInfo.coordinates}
              zoom={10}
              style={{ position: 'relative' }}
              disableDefaultUI>
              <Marker position={doctor.publicProfileInfo.coordinates} />
              <div className={classes.googleMap}>
                <a
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className={classes.showBigMap}
                  style={{
                    textDecoration: isHovered ? 'underline' : 'none',
                  }}
                  href={`https://www.google.com/maps/search/?api=1&query=${doctor.publicProfileInfo.coordinates?.lat}%2C${doctor.publicProfileInfo.coordinates?.lng}`}
                  target='_blank'
                  rel='noreferrer'>
                  {t('showBigMap')}
                </a>
              </div>
            </Map>
          </APIProvider>
        </Box>
      )}
    </Stack>
  ) : null;

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {!doctor && (
          <Stack justifyContent='center' alignItems='center' flex={1}>
            <CircularProgress />
          </Stack>
        )}
        {doctor && (
          <>
            <div
              className={classes.imageContainer}
              onClick={handleNavigateToDoctor}
              style={{ cursor: 'pointer' }}>
              <img
                alt='Doktor profil fotografi'
                className={classes.image}
                src={doctor.thumbnailUrl ?? ProfileImage}
              />
            </div>

            <div style={{ padding: 16, flex: 1 }}>
              <Stack className={classes.avatarCardInfoPane}>
                <Stack flexDirection='column'>
                  <Stack className={classes.nameLine}>
                    <Typography
                      sx={{ fontWeight: 500, fontSize: 24, cursor: 'pointer' }}
                      onClick={handleNavigateToDoctor}>
                      {`${doctor.publicProfileInfo.title ?? ''} ${
                        doctor.name
                      } ${doctor.surname}`}
                    </Typography>

                    <Rating
                      readOnly
                      value={doctor.publicProfileInfo?.rating}
                      sx={{ mt: 1 }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: 18,
                    }}>
                    {doctor.proficiency}
                  </Typography>
                  <Typography>{doctor.publicProfileInfo.phone}</Typography>
                  <Typography>{doctor.publicProfileInfo.email}</Typography>
                  {doctor.publicProfileInfo?.phone && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: '100%',
                        marginBottom: lowWidth ? 16 : 0,
                      }}>
                      <MdWhatsapp
                        style={{ cursor: 'pointer' }}
                        size={28}
                        color={'#25D366'}
                        onClick={handleWhatsappClicked}
                      />
                    </div>
                  )}
                </Stack>
              </Stack>

              <div>{addressPane}</div>
            </div>
          </>
        )}
      </Card>
    </div>
  );
}

export default DoctorSimpleInfo;
