import { useState, useEffect } from 'react';
import { IoIosMenu } from 'react-icons/io';
import './NavBar.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { t } from '../../utils/Language';
import { BUILD_LANGUAGE, PRODUCT_NAME } from '../../config';

const NavBar = ({ style }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuExpanded, setMenuExpanded] = useState(false);

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.slice(1);

      document.getElementById(elementId)?.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    }
  }, [location]);

  const links = (
    <>
      <li className='navbar_menu_title' onClick={() => navigate('/#home')}>
        {t('homepage').toLocaleUpperCase(BUILD_LANGUAGE)}
      </li>
      <li
        className='navbar_menu_title'
        onClick={() => navigate('/#how-to-use')}>
        {t('howToUse').toLocaleUpperCase(BUILD_LANGUAGE)}
      </li>
      <li className='navbar_menu_title' onClick={() => navigate('/#aboutUs')}>
        {t('aboutUs').toLocaleUpperCase(BUILD_LANGUAGE)}
      </li>
      <li className='navbar_menu_title' onClick={() => navigate('/#contact')}>
        {t('contact').toLocaleUpperCase(BUILD_LANGUAGE)}
      </li>
    </>
  );

  return (
    <>
      <nav style={style} className='app__navbar'>
        <div className='app__navbar_row0'>
          <div className='app__navbar-logo'>
            <h1 id='logo' onClick={() => navigate('/#home')}>
              {PRODUCT_NAME}
            </h1>
          </div>
          <div className='app__navbar_menu_spreaded'>
            <ul className='app__navbar-links'>{links}</ul>
          </div>
          <div className='app__navbar_menu_button'>
            <IoIosMenu
              size={50}
              color='white'
              onClick={() => setMenuExpanded((e) => !e)}
            />
          </div>
        </div>
        <div className='app__navbar_row1'>
          <ul
            className={`app__navbar-links-expanded ${
              menuExpanded ? '' : 'app__navbar-menu-collapsed'
            }`}
            onClick={() => setMenuExpanded(false)}>
            {links}
          </ul>
        </div>
        <div className='app__navbar_row1'></div>
      </nav>
    </>
  );
};

export default NavBar;
